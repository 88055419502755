import React, { forwardRef } from "react";

import { Row, Col } from "@webdex/UI/Grid";

import TermMediaModuleHeader from "../Header/components/TermMediaModuleHeader";

import TermDefinition from "./components/TermDefinition";
import TermMediaModuleDefinition from "./components/TermMediaModuleDefinition";
import StyledBody from "./views/Body";
import MediaModuleHeaderContainer from "./views/MediaModuleHeaderContainer";

const Body = forwardRef((props, ref) => {
  const { glossaryTerm } = props;

  return (
    <StyledBody ref={ref}>
      <Row>
        <Col lg="8" xl="6">
          <TermDefinition glossaryTerm={glossaryTerm}/>
        </Col>

        <Col lg="8" xl="2" noGutter>
          <MediaModuleHeaderContainer>
            <Row>
              <Col lg="8" hiddenXlUp>
                <TermMediaModuleHeader glossaryTerm={glossaryTerm}/>
              </Col>
            </Row>
          </MediaModuleHeaderContainer>
          <TermMediaModuleDefinition glossaryTerm={glossaryTerm}/>
        </Col>
      </Row>
    </StyledBody>
  );
});

export default Body;

import React, { useState, useEffect, useRef } from "react";
import ReactHowler from "react-howler";

import { isBrowser } from "@webdex/Utils/Window";
import StyledTermMediaModuleHeader from "./views/TermMediaModuleHeader";
import ControlsAndTimeContainer from "./views/ControlsAndTimeContainer";
import PlaybackTimerText from "./views/PlaybackTimerText";
import ScrubBarContainer from "./views/ScrubBarContainer";
import ScrubBar from "./views/ScrubBar";
import PlayButton from "./views/PlayButton";
import PauseButton from "./views/PauseButton";

const useGetWidthFromRef = (ref) => {
  const [width, setWidth] = useState(1);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);
  return width;
};

const TermMediaModuleHeader = (props) => {
  const {
    glossaryTerm: { glossaryTermModules },
  } = props;
  const { url: audioSrc } = glossaryTermModules?.[0]?.audioFile?.asset ?? {};

  const playerRef = useRef();
  const containerRef = useRef();
  const requestRef = useRef();
  const playbackTimerRef = useRef();
  const throttleRef = useRef();

  const [shouldAutoTick, setShouldAutoTick] = useState(false);
  const [initialPlay, setInitialPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [scrubPosition, setScrubPosition] = useState(0.0);
  const [isScrubbing, setIsScrubbing] = useState(false);

  const width = useGetWidthFromRef(containerRef);
  const duration = playerRef.current?.duration();

  useEffect(() => {
    if (!isBrowser) return;

    const animateSeekPosition = () => {
      if (!isScrubbing) {
        setScrubPosition(playerRef.current.seek());
      }
      throttleRef.current = setTimeout(() => {
        requestRef.current = requestAnimationFrame(animateSeekPosition);
      }, 50);
    };

    if (shouldAutoTick) {
      requestRef.current = requestAnimationFrame(animateSeekPosition);
    }

    return () => {
      cancelAnimationFrame(requestRef.current);
      clearTimeout(throttleRef.current);
    };
  }, [isScrubbing, shouldAutoTick]);

  useEffect(() => {
    const displayTimeISO = new Date(scrubPosition * 1000).toISOString();
    const displayTime =
      displayTimeISO.substr(11, 8) + ":" + displayTimeISO.substr(20, 2);
    playbackTimerRef.current.innerHTML = displayTime;
  }, [scrubPosition]);

  function handleOnPlay() {
    if (!initialPlay) setInitialPlay(true);
    setIsPlaying(true);
    setShouldAutoTick(true);
  }

  function handleOnPause() {
    setIsPlaying(false);
    setShouldAutoTick(false);
    cancelAnimationFrame(requestRef.current);
  }

  function handleMouseDownScrub() {
    setShouldAutoTick(false);
    setIsScrubbing(true);
  }

  function handleMouseUpScrub(e) {
    // console.log("mouse up");
    setIsScrubbing(false);
    playerRef.current.seek(e.target.value);
    if (isPlaying) {
      setShouldAutoTick(true);
    }
  }

  function handleScrubChange(e) {
    setScrubPosition(parseFloat(e.target.value));
  }

  // TODO: probably change fallback audio or refactor when not needed
  return (
    <StyledTermMediaModuleHeader ref={containerRef}>
      <ControlsAndTimeContainer>
        {isPlaying ? (
          <PauseButton onClick={handleOnPause} />
        ) : (
          <PlayButton onClick={handleOnPlay} />
        )}
        <PlaybackTimerText ref={playbackTimerRef}>
          00:00:00:00
        </PlaybackTimerText>
      </ControlsAndTimeContainer>
      <ScrubBarContainer>
        {initialPlay && (
          <ScrubBar
            min="0"
            max={duration}
            step="0.1"
            value={scrubPosition}
            onChange={handleScrubChange}
            onMouseDown={handleMouseDownScrub}
            onPointerDown={handleMouseDownScrub}
            onMouseUp={handleMouseUpScrub}
            onPointerUp={handleMouseUpScrub}
            width={width - 30} // not great
          />
        )}
      </ScrubBarContainer>
      <ReactHowler
        ref={playerRef}
        src={
          audioSrc ??
          "https://ia800300.us.archive.org/13/items/10s8.mp4/10s8_512kb.mp4"
        }
        playing={isPlaying}
        onPlay={handleOnPlay}
        onPause={handleOnPause}
        preload
        html5
      />
    </StyledTermMediaModuleHeader>
  );
};

export default TermMediaModuleHeader;

import React, {
  useContext,
  useEffect,
  useMemo,
  useRef
} from "react";

import { Metadata } from "@webdex/Core";
import AppHeader from "@webdex/Layouts/App/components/Header";
import { MemeOverlay } from "@webdex/Layouts/App/components/MemeOverlay";
import SubLayoutContext from "@webdex/Layouts/App/subLayouts/contexts/SubLayoutContext";
import { isBrowser } from "@webdex/Utils/Window";

import Body from "./components/Body";
import Header from "./components/Header";
import StyledGlossaryTerm from "./views/GlossaryTerm";

const GlossaryTerm = (props) => {
  const {
    data: { glossaryTerm },
  } = props;

  const { isTransitioning, lastScrollHTMLTopBeforeTransition } =
    useContext(SubLayoutContext);

  const bodyElRef = useRef(null);
  const headerElRef = useRef(null);
  const isFirstTransitionRef = useRef(true);
  const lastScrollHTMLTopBeforeTransitionRef = useRef(
    lastScrollHTMLTopBeforeTransition
  );

  useEffect(() => {
    lastScrollHTMLTopBeforeTransitionRef.current =
      lastScrollHTMLTopBeforeTransition;
  }, [lastScrollHTMLTopBeforeTransition]);

  useEffect(() => {
    if (!isBrowser() || !bodyElRef.current) {
      return;
    }

    const bodyEl = bodyElRef.current;

    if (isTransitioning) {
      if (isFirstTransitionRef.current === true) {
        isFirstTransitionRef.current = false;
      } else {
        // Exiting.
        const lastScrollHTMLTopBeforeTransition =
          lastScrollHTMLTopBeforeTransitionRef.current;

        bodyEl.style.position = "relative";
        bodyEl.style.top = `-${lastScrollHTMLTopBeforeTransition}px`;
      }
    }

    return () => {
      bodyEl.style.position = "static";
      bodyEl.style.top = `0`;
    };
  }, [isTransitioning]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <Metadata metadata={glossaryTerm.metadata} />
        <AppHeader invert />
        <MemeOverlay
          imgWidth={200}
          // intervalDuration={5000}
          idleDuration={10000}
          invert
        />
        <StyledGlossaryTerm>
          <Header ref={headerElRef} glossaryTerm={glossaryTerm} />
          <Body ref={bodyElRef} glossaryTerm={glossaryTerm} />
        </StyledGlossaryTerm>
      </React.Fragment>
    );
  }, [
    bodyElRef,
    headerElRef,
    glossaryTerm
  ]);
};

export default GlossaryTerm;

import classNames from "classnames";
import styled from "styled-components";

const TermTitleWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__header__term-title-wrapper"
    )
  };
})`
`;

TermTitleWrapper.defaultProps = {};

export default TermTitleWrapper;

import classNames from "classnames";
import styled from "styled-components";

const StyledTermMediaModuleTitle = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__body__term-media-module-title"
    )
  };
})`
  ${({ theme: { colors, space, fontSizes, lineHeights } }) => `
    background: ${ colors.black };
    color: ${ colors.white };
    font-size: ${ fontSizes.large };
    line-height: ${ lineHeights.large };
    padding-bottom: ${ space.medium };

    @media (max-width: 768px) {
      font-size: ${ fontSizes.medium };
      line-height: ${ lineHeights.medium };
    }
  `}
`;

export default StyledTermMediaModuleTitle;

import classNames from "classnames";
import styled from "styled-components";

const TermMediaModuleDefinition = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__body__term-media-module-definition"
    )
  };
})`
  ${({ theme: { colors, space } }) => `
    background: ${ colors.black };
    color: ${ colors.white };
    min-height: 100%; // This is causing the sticky problems. Fix when time.
    padding:
      ${ space.medium }
      calc(${ space.small } + ${ space.xSmall })
      ${ space.medium }
      ${ space.small };
    position: sticky;
    top: 0;
  `}
`;

export default TermMediaModuleDefinition;

import classNames from "classnames";
import styled from "styled-components";

const ScrubBar = styled.input.attrs(props => {
    const {
      className
    } = props;
  
    return {
      type: 'range',
      className: classNames(
        className,
        "glossary-term-page__header__term-media-module-header-scrubbar"
      )
    };
  })`
    ${({ theme: { colors }, width }) => `
      -webkit-appearance: none;
      overflow: hidden;
      width: ${ width }px;
      height: 10px;
      background-color: ${ colors.black };
    
      &::-webkit-slider-runnable-track {
        height: 10px;
        -webkit-appearance: none;
        color: ${ colors.black };
        margin-top: -10px;
      }
    
      &::-webkit-slider-thumb {
        width: 20px;
        border: none;
        -webkit-appearance: none;
        height: 9px;
        background: ${ colors.white };
        ..box-shadow: ${ (width * -1) }px 0 0 ${ width }px ${ colors.white };
      }
    
      &::-moz-range-track {
        background: ${ colors.black };
        height: 10px;
      }
    
      &::-moz-range-progress {
        background-color: ${ colors.white }; 
        height: 10px;
      }
    
      &::-moz-range-thumb {
        border-width: 0;
        height: 10px;
        width: 10px;
        border-radius: 0;
        background: ${ colors.black };
      }
    
      &::-ms-track {
        background: transparent; 
        border-color: transparent;
        color: transparent;
      }
    
      &::-ms-fill-lower {
        background: ${ colors.white };
      }
    
      &::-ms-fill-upper {
        background: ${ colors.black };
      }
    `}
  `;
  
  ScrubBar.defaultProps = { };
  
  export default ScrubBar;

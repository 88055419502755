import classNames from "classnames";
import styled from "styled-components";

import { Container } from "@webdex/UI/Grid";

const MediaModuleHeaderContainer = styled(Container).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__body__media-module-header-container"
    )
  };
})`
  ${({ theme: { colors } }) => `
    background: ${ colors.black };
  `}
`;

MediaModuleHeaderContainer.defaultProps = {};

export default MediaModuleHeaderContainer;

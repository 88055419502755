import classNames from "classnames";
import styled from "styled-components";

const PlaybackTimerText = styled.div.attrs(props => {
    const {
      className
    } = props;
  
    return {
      className: classNames(
        className,
        "glossary-term-page__header__term-media-module-header-playback-timer-text"
      )
    };
  })`
    ${({ theme: { colors, fonts, fontSizes } }) => `
      font-family: ${ fonts.monospace };
      font-size: ${ fontSizes.large };
      color: ${ colors.white };
      padding-bottom: 6px;
    `}
  `;
  
  PlaybackTimerText.defaultProps = {};
  
  export default PlaybackTimerText;

import React from "react";
import { navigate } from "gatsby";

import { Button } from "@webdex/UI/Button";
import { ResponsiveText } from "@webdex/UI/Typography";

import HomeButtonWrapper from "./views/HomeButtonWrapper";
import StyledTermHeader from "./views/TermHeader";
import TermText from "./views/TermText";
import TermTitleWrapper from "./views/TermTitleWrapper";

const TermHeader = props => {
  const { glossaryTerm } = props;

  const goHome = () => {
    navigate("/", { state: { disableScrollUpdate: true } })
  };

  return (
    <StyledTermHeader>
      <HomeButtonWrapper>
        <Button invert={true} role="link" onClick={goHome}>
          <ResponsiveText
            as="span"
            sm="small"
            xs="xSmall"
            weight="bold"
          >
            Go to Full Glossary
          </ResponsiveText>
        </Button>
      </HomeButtonWrapper>
      <TermTitleWrapper>
        <TermText>
          “{ glossaryTerm.term }”
        </TermText>
{/*        <TagText>
          (Glossary Term)
        </TagText>*/}
      </TermTitleWrapper>
    </StyledTermHeader>
  );
};

export default TermHeader;

import classNames from "classnames";
import styled from "styled-components";

const ControlsAndTimeContainer = styled.div.attrs(props => {
    const {
      className
    } = props;
  
    return {
      className: classNames(
        className,
        "glossary-term-page__header__term-media-module-header-controls-time-container"
      )
    };
  })`
    ${({ theme: { colors, space } }) => `
      display: flex;
      flex-direstion: row;
      justify-content: space-between;
      align-items: center;
      height: 42px;
    `}
  `;
  
  ControlsAndTimeContainer.defaultProps = {};
  
  export default ControlsAndTimeContainer;

import React, { forwardRef } from "react";

import { Row, Col } from "@webdex/UI/Grid";

import TermMediaModuleHeader from "./components/TermMediaModuleHeader";
import TermHeader from "./components/TermHeader";
import StyledHeader from "./views/Header";

const Header = forwardRef((props, ref) => {
  const { glossaryTerm } = props;

  return (
    <StyledHeader ref={ref}>
      <Row>
        <Col lg="8" xl="6">
          <TermHeader glossaryTerm={glossaryTerm}/>
        </Col>

        <Col xl="2" hiddenXlDown noGutter>
          <TermMediaModuleHeader glossaryTerm={glossaryTerm}/>
        </Col>
      </Row>
    </StyledHeader>
  );
});

export default Header;

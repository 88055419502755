import classNames from "classnames";
import styled from "styled-components";

const HomeButtonWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__header__home-button-wrapper"
    )
  };
})`
  ${({ theme: { space } }) => `
    margin: ${ space.small } 0;
  `}
`;

export default HomeButtonWrapper;

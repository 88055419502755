import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { Anchor } from "@webdex/UI/Button";
import { ResponsiveParagraph } from "@webdex/UI/Paragraph";

import StyledTermMediaModuleDefinition from "./views/TermMediaModuleDefinition";
import StyledTermMediaModuleDefinitionImage from "./views/TermMediaModuleDefinitionImage";
import StyledTermMediaModuleTitle from './views/TermMediaModuleTitle';

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph lg="medium" sm="small" xs="small" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const TermMediaModuleDefinition = props => {
  const { glossaryTerm: { glossaryTermModules } } = props;
  const { _rawAudioTranscript } = glossaryTermModules[0] ?? {}
  const { fluid } = glossaryTermModules?.[0]?.coverImage?.asset ?? {}
  const { coverImageAltText, episodeTitle, audioTimeStamp } = glossaryTermModules[0] ?? {}

  return (
    <StyledTermMediaModuleDefinition>
      <StyledTermMediaModuleTitle>
        {episodeTitle}
      </StyledTermMediaModuleTitle>
      <StyledTermMediaModuleTitle>
        {audioTimeStamp}
      </StyledTermMediaModuleTitle>
      <BlockContent
        blocks={_rawAudioTranscript}
        serializers={serializers}
      />
      <StyledTermMediaModuleDefinitionImage fluid={fluid} alt={coverImageAltText} />
    </StyledTermMediaModuleDefinition>
  );
}

export default TermMediaModuleDefinition;


import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { Anchor } from "@webdex/UI/Button";
import { ResponsiveParagraph } from "@webdex/UI/Paragraph";

import StyledTermDefinition from "./views/TermDefinition";

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph lg="large" sm="medium" xs="small" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const TermDefinition = props => {
  const { glossaryTerm: { _rawDefinition }} = props;

  return (
    <StyledTermDefinition>
      <BlockContent
        blocks={_rawDefinition}
        serializers={serializers}
      />
    </StyledTermDefinition>
  );
}

export default TermDefinition;


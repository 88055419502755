import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
  all: unset;
  padding: 12px 12px 0 0;
`

const PauseButton = forwardRef((props, ref) => {
    const {
      className,
      onClick
    } = props;
  
    return (
      <Button className={className} ref={ref} onClick={onClick}>
        <svg
          width="26"
          height="35"
          viewBox="0 0 26 35"
          stroke='#000'
          strokeLinecap="round"
          strokeWidth="1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d)">
            <rect x="15" width="7" height="27" rx="2" fill="white"/>
            <rect x="15.5" y="0.5" width="6" height="26" rx="1.5" stroke="black"/>
          </g>
          <g filter="url(#filter1_d)">
            <rect x="4" width="7" height="27" rx="2" fill="white"/>
            <rect x="4.5" y="0.5" width="6" height="26" rx="1.5" stroke="black"/>
          </g>
          <defs>
            <filter id="filter0_d" x="11" y="0" width="15" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            <filter id="filter1_d" x="0" y="0" width="15" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
          </defs>
        </svg>
      </Button>
    );
  });
  
  PauseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    fillColor: PropTypes.string,
    strokeColor: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };
  
  PauseButton.defaultProps = {
    color: "currentColor"
  }
  
  export default PauseButton;
  

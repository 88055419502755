import classNames from "classnames";
import styled from "styled-components";
import Img from "gatsby-image";

const StyledTermMediaModuleDefinitionImage = styled(Img).attrs((props) => {
  const { className } = props;

  return {
    className: classNames(className, "glossary-term-page__body__term-media-module-image"),
  };
})`
  ${() => ``}
`;

StyledTermMediaModuleDefinitionImage.propTypes = {};

StyledTermMediaModuleDefinitionImage.defaultProps = {};

export default StyledTermMediaModuleDefinitionImage;

import classNames from "classnames";
import styled from "styled-components";

const ScrubBarContainer = styled.div.attrs(props => {
    const {
      className
    } = props;
  
    return {
      className: classNames(
        className,
        "glossary-term-page__header__term-media-module-header-scrubbar-container"
      )
    };
  })`
    ${() => `
      height: 10px;
      vertical-align: top;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `}
  `;
  
  ScrubBarContainer.defaultProps = {};
  
  export default ScrubBarContainer;

import classNames from "classnames";
import styled from "styled-components";

const TermMediaModuleHeader = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__header__term-media-module-header"
    )
  };
})`
  ${({ theme: { colors, space } }) => `
    // Change these styles when content gets added. Flex stuff just for the
    // note to Davidy.
    background: ${ colors.black };
    color: ${ colors.white };
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    padding:
      ${ space.medium }
      calc(${ space.small } + ${ space.xSmall })
      1px
      ${ space.small };
  `}
`;

TermMediaModuleHeader.defaultProps = {};

export default TermMediaModuleHeader;

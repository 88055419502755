import classNames from "classnames";
import styled from "styled-components";

const TermDefinition = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__body__term-definition"
    )
  };
})`
  ${({ theme: { colors, space } }) => `
    background: ${ colors.white };
    color: ${ colors.black };
    min-height: 100%;
    padding: ${ space.medium } 0 ${ space.xLarge };

    @media (max-width: 768px) {
      padding-bottom: ${ space.large };
    }
  `}
`;

export default TermDefinition;

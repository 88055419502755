import React from "react";
import { graphql } from "gatsby";

import GlossaryTermPageComponent from "@webdex/Pages/GlossaryTerm";

const GlossaryTerm = props => {
  const { data } = props;

  return (
    <GlossaryTermPageComponent data={data}/>
  );
};

export const query = graphql`
  query GlossaryTerm($slug: String) {
    glossaryTerm: sanityGlossaryTerm(slug: { current: { eq: $slug } }) {
      ...fieldsOnGlossaryTerm
    }
  }
`;

export default GlossaryTerm;

import classNames from "classnames";
import styled from "styled-components";

import { Container } from "@webdex/UI/Grid";

const GlossaryTerm = styled(Container).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page"
    )
  };
})`
  ${({ theme: { chrome, colors } }) => `
    background: ${ colors.white };
    color: ${ colors.black };
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    min-height: 100vh;
    min-width: 0;
    position: relative;
  `}
`;

GlossaryTerm.defaultProps = {};

export default GlossaryTerm;

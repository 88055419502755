import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
  all: unset;
  padding: 12px 12px 0 0;
`

const PlayButton = forwardRef((props, ref) => {
  const {
    className,
    onClick
  } = props;

  return (
    <Button className={className} ref={ref} onClick={onClick}>
      <svg
        height="39"
        width="38"
        fill='#fff'
        stroke='#000'
        strokeLinecap="round"
        strokeWidth="1"
        viewBox="0 0 38 39"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path d="M31.7814 12.8646C33.9586 13.9801 33.9569 17.0924 31.7785 18.2059L9.24502 29.7232C7.24825 30.7438 4.87858 29.2929 4.8798 27.0505L4.89234 3.99423C4.89356 1.75181 7.26481 0.303197 9.26047 1.3257L31.7814 12.8646Z" fill="white"/>
          <path d="M31.551 17.7606L9.01748 29.278C7.3535 30.1285 5.37878 28.9194 5.3798 27.0507L5.39234 3.99447C5.39336 2.12579 7.36939 0.918608 9.03244 1.7707L31.5534 13.3096C33.3677 14.2392 33.3663 16.8328 31.551 17.7606Z" stroke="black"/>
        </g>
        <defs>
          <filter id="filter0_d" x="0.879791" y="0.991699" width="36.5335" height="37.0642" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>
      </svg>
    </Button>
  );
});

PlayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

PlayButton.defaultProps = {
  color: "currentColor"
}

export default PlayButton;

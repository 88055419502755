import classNames from "classnames";
import styled from "styled-components";

import { ResponsiveText } from "@webdex/UI/Typography";

const TermText = styled(ResponsiveText).attrs(props => {
  const {
    className
  } = props;

  return {
    as: "span",
    className: classNames(
      className,
      "glossary-term-page__header__term-header__text"
    )
  };
})`
  vertical-align: bottom;
`;

TermText.defaultProps = {
  lg: "xxLarge",
  md: "xLarge",
  sm: "xLarge",
  xs: "large"
};

export default TermText;

import classNames from "classnames";
import styled from "styled-components";

import { media } from "styled-bootstrap-grid";

const TermHeader = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "glossary-term-page__header__term-header"
    )
  };
})`
  ${({ theme: { colors, space } }) => `
    background: ${ colors.white };
    color: ${ colors.black };
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  `}

  ${media.xl`
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  `}
`;

TermHeader.defaultProps = {};

export default TermHeader;
